import {
	S7_COMPANY,
	S7_IMAGE_SERVER,
	S7_PRESET_MAINIMAGE,
	S7_PRESET_THUMBNAIL,
} from '@/data/constants/Scene7';

export const useScene7ImgUrl = () => {
	const getMainImageUrl = (imgName = 'NoImageIcon'): string =>
		`${S7_IMAGE_SERVER}${S7_COMPANY}${imgName}?${S7_PRESET_MAINIMAGE}`;

	const getThumbnailUrl = (imgName = 'NoImageIcon'): string =>
		`${S7_IMAGE_SERVER}${S7_COMPANY}${imgName}?${S7_PRESET_THUMBNAIL}`;

	const getSwatchUrl = (identifier = 'NoImageIcon'): string =>
		`${S7_IMAGE_SERVER}${S7_COMPANY}/sw_${identifier}_enabled`;

	const getCrossSellUrl = (imgName = 'NoImageIcon', colorSwatch: string): string => {
		let newImage;
		if (imgName?.includes('_')) {
			newImage = imgName?.split('_')[0];
		} else {
			newImage = imgName;
		}

		return `${S7_IMAGE_SERVER}${S7_COMPANY}${newImage}_${colorSwatch}?${S7_PRESET_MAINIMAGE}`;
	};

	return { getMainImageUrl, getThumbnailUrl, getSwatchUrl, getCrossSellUrl };
};
