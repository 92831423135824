import { Box } from '@mui/material';
import { FC } from 'react';

export const BazaarVoiceRating: FC<{
	storeId: string;
	partNumber: string;
}> = ({ storeId, partNumber }) =>
	storeId ? (
		<Box
			data-bv-show="inline_rating"
			data-bv-seo="false"
			data-bv-product-id={`${storeId}_${partNumber}`}
		></Box>
	) : null;
