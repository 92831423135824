/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { MuiCardMedia } from '@/components/blocks/MuiCardMedia';
import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { BazaarVoiceRating } from '@/components/blocks/WacoalProductCard/parts/BazaarVoiceRating';
import { productCardMediaSX } from '@/components/blocks/WacoalProductCard/styles/cardMedia';
import { carouselProductCardSX } from '@/components/blocks/WacoalProductCard/styles/carouselCard';
import { useScene7ImgUrl } from '@/data/Content/Scene7ImgUrl';
import { useStoreName } from '@/data/Content/StoreName';
import { useProductCard } from '@/data/Content/_ProductCard';
import { useProductEvents } from '@/data/Content/_ProductEvents';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { ProductType } from '@/data/types/Product';
import { parseHTML } from '@/utils/parseHTML';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

export const WacoalCarouselProductCard: FC<{
	product: ProductType;
	clickAction?: () => void;
	emsName: string;
	selectedSwatchValue: any;
}> = ({ product, clickAction, emsName, selectedSwatchValue }) => {
	const { splashTitle, splashPromo } = useProductCard(product);
	const { onClick } = useProductEvents({ product });
	const { settings } = useSettings();

	const { isWacoal } = useStoreName();
	const priceDisplayNLS = useLocalization('PriceDisplay');
	const { getMainImageUrl, getCrossSellUrl } = useScene7ImgUrl();
	const { thumbnail, altThumbnail, newThumbnail } = useMemo(() => {
		const thumbnailRaw = product?.thumbnailRaw;
		return {
			thumbnail: getMainImageUrl(thumbnailRaw ? thumbnailRaw : ''),
			altThumbnail: getMainImageUrl(product.partNumber),
			newThumbnail: getCrossSellUrl(thumbnailRaw ? thumbnailRaw : '', selectedSwatchValue),
		};
	}, [product, selectedSwatchValue, getMainImageUrl, getCrossSellUrl]);
	const [image, setImage] = useState(thumbnail);
	const SplashDisplay: FC = () =>
		splashTitle || splashPromo ? (
			<Box
				component="span"
				sx={{
					fontSize: '15px',
					color: '#000000',
					backgroundColor: isWacoal ? '#EAE6E1' : '#D7B9D5',
					lineHeight: '24px',
				}}
			>
				{splashTitle ? parseHTML(splashTitle) : null} {splashPromo ? parseHTML(splashPromo) : null}
			</Box>
		) : null;

	const handleMouseOver = () => {
		setImage(altThumbnail);
	};

	const handleMouseOut = () => {
		emsName.includes('ProductCrossSells') ? thumbnailImage() : setImage(thumbnail);
	};
	const thumbnailImage = async () => {
		const url: any = newThumbnail;
		// console.log('selectedSwatchValue ', selectedSwatchValue);
		try {
			const response = await fetch(url);
			const json = response;
			if (json.status !== 200) {
				setImage(thumbnail);
			} else {
				setImage(newThumbnail);
			}
		} catch (error) {
			setImage(thumbnail);
		}
	};

	useEffect(() => {
		emsName.includes('ProductCrossSells') && thumbnailImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newThumbnail, thumbnail]);

	return (
		<Card onClick={onClick(clickAction)} sx={carouselProductCardSX}>
			<Box component="a" href={product.seo?.href}>
				<MuiCardMedia
					sx={productCardMediaSX}
					component="img"
					image={image}
					title={product.name}
					onMouseOver={handleMouseOver}
					onMouseOut={handleMouseOut}
				></MuiCardMedia>
			</Box>
			<CardContent
				sx={{
					'&.MuiCardContent-root': {
						p: 0,
						flexGrow: 1,
					},
				}}
			>
				<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
					<Box
						component="a"
						href={product.seo?.href}
						color="textPrimary"
						sx={{ textDecoration: 'none' }}
					>
						<Typography
							variant="body2"
							id={product.partNumber}
							data-testid={product.partNumber}
							sx={{
								fontSize: '15px',
								color: '#252525',
								lineHeight: '18px',
								letterSpacing: '0.56px',
							}}
						>
							{product.name}
						</Typography>
					</Box>
					<Box sx={{ marginTop: 'auto' }}>
						<SplashDisplay />
						<BazaarVoiceRating storeId={settings?.storeId} partNumber={product.partNumber} />
						{product?.productPrice?.min ? (
							<Typography variant="body1" sx={{ fontSize: '12px', letterSpacing: '0.5px' }}>
								<PriceDisplay
									currency={product.productPrice.currency}
									min={product.productPrice.min}
									{...(product.productPrice.max ? { max: product.productPrice.max } : {})}
								></PriceDisplay>
							</Typography>
						) : (
							<Typography variant="body1">{priceDisplayNLS.Labels.Pending.t()}</Typography>
						)}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};
