import { SxProps } from '@mui/material';

export const carouselProductCardSX: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	padding: '0 10px',
	width: '100%',
	maxWidth: '245px',
	backgroundColor: 'transparent',
	boxShadow: 'none',
	borderRadius: 0,
};
