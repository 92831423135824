import { SxProps, Theme } from '@mui/material';

export const productCardMediaSX: SxProps<Theme> = {
	// mt: 2,
	mb: 1,
	mx: 'auto',
	backgroundSize: 'contain',
	position: 'relative',
	border: '1px solid #8d8d8d',
	// height: (theme) => theme.dimensions.productCard.thumbnail + 'px',
};
